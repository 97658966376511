/**
 * TODO: Fix this to come from the component library, 
 *   not this copy
 */
import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export type SpinnerProps = {
  open: boolean
  children?: React.ReactNode
  title?: string
  dismissable?: boolean
  onClose?: () => unknown
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const noop = () => {}

export function Spinner({
  open = false,
  title,
  dismissable = false,
  onClose = noop,
  children,
}: SpinnerProps) {
  const [isOpen, setIsOpen] = useState(() => open)

  const backdropProps = {
    onClick: () => {
      if (dismissable) {
        setIsOpen(false)
        onClose()
      }
    },
  }

  useEffect(() => {
    /**
     * TODO: Test the behavior of the modal open state
     *   managed separately from the open property
     */
    if (isOpen !== open) setIsOpen(open)
  }, [open])

  return (
    <Modal open={isOpen} onClose={onClose} BackdropProps={backdropProps}>
      <Box sx={style} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        {title && (
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        )}
        {children ? children : <CircularProgress />}
      </Box>
    </Modal>
  )
}

export default Spinner
