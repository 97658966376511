import axios from 'axios'
import axiosRetry from 'axios-retry'

export function useAPI() {
  const BASE_URL = document.location.hostname === 'localhost' ? 'http://localhost:5001/emche-dad-jokes/us-central1' : 'https://us-central1-emche-dad-jokes.cloudfunctions.net'
  const client = axios.create({ baseURL: BASE_URL })
  axiosRetry(client, { retries: 2 })

  return { API: client }
}

export default useAPI
