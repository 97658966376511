import Typography from '@mui/material/Typography'

import BackFab from './BackFab'
import FabControls from './FabControls'
import { MenuItem } from '../features/Drawer'

export function Credits() {
  const resources: MenuItem[] = [
    {
      slug: 'ux-designer',
      primaryContent: 'Emily Chilaka',
      secondaryContent: 'Dad Jokes Curator-in-Chief, UX Designer',
      href: 'https://www.linkedin.com/in/emily-chilaka/',
      tags: ['people']
    },
    {
      slug: 'mui',
      primaryContent: 'Material UI Project',
      href: 'https://mui.com/',
      tags: ['tools']
    },
    {
      slug: 'gen/favicon',
      primaryContent: 'Favicon generator project',
      href: 'https://favicon.io/',
      tags: ['tools']
    },
    {
      slug: 'gen/mailto',
      primaryContent: 'Mailto link generator project',
      href: 'https://mailtolink.me/',
      tags: ['tools']
    },
    {
      slug: 'mens-health-dad-jokes',
      primaryContent: 'The 200 Best Dad Jokes of All-Time Guaranteed to Make You Laugh',
      secondaryContent: 'menshealth.com',
      href: 'https://www.menshealth.com/trending-news/a34437277/best-dad-jokes/'
    },
    {
      slug: 'parade-dad-jokes',
      primaryContent: '150 Best Dad Jokes That Are So Bad And So Funny!',
      secondaryContent: 'parade.com',
      href: 'https://parade.com/940979/kelseypelzer/best-dad-jokes/'
    }
  ]

  return (
    <>
      <Typography variant="h2">Credits</Typography>
      <Typography variant="body2" component="div">
        <ul>
          {resources.map(({ slug, primaryContent, secondaryContent, href }) => (
            <li key={slug}>
              <a href={href} target="_blank">{primaryContent}</a> {secondaryContent && (`- ${secondaryContent}`)}
            </li>
          ))}
        </ul>
      </Typography>

      <FabControls>
        <BackFab href="/setup" />
      </FabControls>
    </>
  )
}

export default Credits