import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { initializeApp } from 'firebase/app'
import { Analytics, getAnalytics, isSupported as analyticsIsSupported } from 'firebase/analytics'

import Credits from './components/Credits'
import Jokes from './components/Jokes'
import Setup from './components/Setup'
import AppDrawer from './features/Drawer'
import TalentInstructions from './components/InstructionsForPresenters'
import TeamInstructions from './components/InstructionsForTeams'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBG-hXKnTiyWvKu1ag0iSh4QeG8fjt_7eg',
  authDomain: 'emche-dad-jokes.firebaseapp.com',
  projectId: 'emche-dad-jokes',
  storageBucket: 'emche-dad-jokes.appspot.com',
  messagingSenderId: '111751925694',
  appId: '1:111751925694:web:ecc77136e70a69ade6f3ef',
  measurementId: 'G-8ZJDWLFL9S'
}

function App() {
  const [, setAnalytics] = useState<Analytics>()
  /**
   * Notes on theming responsive font sizes: https://mui.com/material-ui/customization/typography/#responsive-font-sizes
   */
  const theme = createTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      body2: {
        fontSize: '1.25rem'
      }
    },    
  })

  useEffect(() => {
    const app = initializeApp(firebaseConfig)
    analyticsIsSupported().then(supported => {
      if(supported) setAnalytics(getAnalytics(app))
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppDrawer>
          <Routes>
            <Route path="/" element={<Setup />} />
            <Route path="instructions">
              <Route path="team" element={<TeamInstructions />} />
              <Route path="talent" element={<TalentInstructions />} />
            </Route>
            <Route path="/credits" element={<Credits />} />
            <Route path="/jokes/:jokeNumber" element={<Jokes />} />
            <Route path="/jokes" element={<Jokes />} />
            <Route path="/setup" element={<Setup />} />
          </Routes>
        </AppDrawer>
      </Router>
    </ThemeProvider>
  )
}

export default App
