import { ReactNode } from 'react'
import Container from '@mui/material/Container'

interface LayoutProps {
  children?: ReactNode
}

export function Layout({ children }: LayoutProps) {
  return (
    <Container sx={{ margin: '1.5rem auto', padding: '1rem' }} maxWidth={'md'}>
      {children}
    </Container>
  )
}

export default Layout
