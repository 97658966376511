/* eslint-disable no-undef */
import { useNavigate } from 'react-router-dom'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import MicIcon from '@mui/icons-material/Mic'
import RuleIcon from '@mui/icons-material/Rule'
import HelpIcon from '@mui/icons-material/Help'
import AwardIcon from '@mui/icons-material/EmojiEvents'
import Divider from '@mui/material/Divider'

import { MenuSubTitle } from './MenuSubTitle'
import { MenuItem } from './types'
import { isValidAppPath, isValidExternalURL, itemByTags, useDrawer } from './lib'

export function NavMenu() {
  const drawer = useDrawer()
  const navigate = useNavigate()

  const creditsMenuItem: MenuItem = {
    slug: 'credits',
    primaryContent: 'Credits',
    icon: <AwardIcon />,
    path: '/credits'
  }

  const menuItems: MenuItem[] = [
    {
      slug: 'getting-started',
      primaryContent: 'Getting started',
      icon: <HelpIcon />,
      tags: ['feature'],
      path: '/setup',
    },
    {
      slug: 'instructions-team',
      primaryContent: 'For Teams',
      icon: <RuleIcon />,
      tags: ['docs', 'guide'],
      path: '/instructions/team',
    },
    {
      slug: 'instructions-talent',
      primaryContent: 'For Talent',
      icon: <RuleIcon />,
      tags: ['docs', 'guide'],
      path: '/instructions/talent',
    },
    {
      slug: 'jokes',
      primaryContent: 'Jokes',
      icon: <MicIcon />,
      tags: ['feature'],
      path: '/jokes',
    },
    {
      slug: 'mui-icons',
      primaryContent: 'Material UI Icons',
      icon: <SquareFootIcon />,
      tags: ['tools'],
      href: 'https://mui.com/material-ui/material-icons',
      openInNewTab: true
    }
  ]

  const handleMenuSelect = (item: Partial<MenuItem>) => {
    if(isValidExternalURL(item.href)) {
      const target = item.openInNewTab ? '_blank' : '_self'
      console.warn(`Navigating to external URL: ${item.href}`)
      global.window.open(item.href, target)
    } else if(isValidAppPath(item.path)) {
      navigate(item.path)
    }
    drawer?.toggleDrawer()
  }

  return (
    <>
      <List>
        {menuItems.filter((item) => itemByTags(item, ['feature'])).map(({ icon, onClick, primaryContent, secondaryContent, ...item }) => (
          <ListItem key={`menu-li-${item.slug}`} disablePadding>
            <ListItemButton data-slug={item.slug} onClick={onClick ?? (() => handleMenuSelect(item))}>
              {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={primaryContent} secondary={secondaryContent} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <li><MenuSubTitle>Guides</MenuSubTitle></li>
        {menuItems.filter((item) => itemByTags(item, ['docs', 'guide'])).map(({ icon, onClick, primaryContent, secondaryContent, ...item }) => (
          <ListItem key={`menu-li-tool-${item.slug}`} disablePadding>
            <ListItemButton data-slug={item.slug} onClick={onClick ?? (() => handleMenuSelect(item))}>
              {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={primaryContent} secondary={secondaryContent} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <li><MenuSubTitle>Tools</MenuSubTitle></li>
        {menuItems.filter((item) => itemByTags(item, ['tools'])).map(({ icon, onClick, primaryContent, secondaryContent, ...item }) => (
          <ListItem key={`menu-li-tool-${item.slug}`} disablePadding>
            <ListItemButton data-slug={item.slug} onClick={onClick ?? (() => handleMenuSelect(item))}>
              {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={primaryContent} secondary={secondaryContent} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem key='menu-li-credits' disablePadding>
          <ListItemButton data-slug='credits' onClick={() => handleMenuSelect(creditsMenuItem)}>
            <ListItemIcon>{creditsMenuItem.icon}</ListItemIcon>
            <ListItemText primary={creditsMenuItem.primaryContent} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  )
}

export default NavMenu
/* eslint-enable no-undef */