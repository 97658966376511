import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

export function MenuSubTitle({ children }: Props) {
  return (
    <Typography
      sx={{ mt: 0.5, ml: 2 }}
      color="text.secondary"
      display="block"
      variant="caption"
    >
      {children}
    </Typography>
  )
}