import React, { useCallback } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ReactSwipeableViews from 'react-swipeable-views'
import { bindKeyboard } from 'react-swipeable-views-utils'
import DownwardIcon from '@mui/icons-material/ArrowDownward'
import { Spinner } from './Spinner'

import FabControls from './FabControls'
import BackFab from './BackFab'

import useJokes from '../hooks/useJokes'
import { isNumeric } from '../type-guards'

const SwipeableViews = bindKeyboard(ReactSwipeableViews)

export function Jokes() {
  const { jokeNumber } = useParams()
  const { isLoading, jokes } = useJokes()
  const position = isNumeric(jokeNumber) ? parseInt(jokeNumber) : 1
  const invalidJokeNumber = !isNumeric(jokeNumber)
  const outOfLoadedJokesBounds = jokes.length > 0 && isNumeric(jokeNumber) && position > jokes.length
  const navigate = useNavigate()

  const updatePosition = useCallback((currentIndex: number) => {
    navigate(`/jokes/${currentIndex + 1}`)
  }, [])

  const advancePosition = useCallback(() => {
    const newPosition = position >= jokes.length ? 1 : position + 1
    navigate(`/jokes/${newPosition}`)  
  }, [position, jokes.length])

  return (
    <>
      {!isLoading && (
        <>
          {(invalidJokeNumber || outOfLoadedJokesBounds) && <Navigate to="/jokes/1" />}
          <Grid container spacing={0} sx={{ minHeight: '85vh' }}>
            <Grid item sx={{ maxWidth: { xs: '86vw', md: '75vw' } }}>
              <SwipeableViews index={position - 1} onChangeIndex={updatePosition}>
                {jokes.map(({ setup, punchline }, index) => (
                  <Paper key={`joke-${index}`} sx={{ textAlign: 'center', paddingBottom: '3rem' }} elevation={0}>
                    <Divider sx={{ marginTop: '2rem', marginBottom: '2rem' }}>SETUP</Divider>
                    <Typography variant="h4">{setup}</Typography>
                    <Divider sx={{ marginTop: '2rem', marginBottom: '2rem' }}>PUNCHLINE</Divider>
                    <Typography variant="h5">{punchline}</Typography>
                  </Paper>
                ))}
              </SwipeableViews>
            </Grid>
          </Grid>
        </>        
      )}
      <FabControls>
        <Fab size="large" color="primary" variant="extended" onClick={advancePosition}>
          <DownwardIcon sx={{ mr: 1 }} />
          {position}/{jokes.length} jokes
        </Fab>
        <BackFab href="/setup" />
        <Fab disabled><FavoriteIcon /></Fab>
      </FabControls>
      <Spinner title="Loading jokes..." open={isLoading} />
    </>
  )
}

export default Jokes
