import Fab, { FabProps } from '@mui/material/Fab'
import CloseIcon from '@mui/icons-material/Close'

export function BackFab({ children, variant, ...rest }: FabProps) {
  return (
    <Fab {...rest} variant={children ? 'extended' : variant}>
      <CloseIcon sx={{ mr: children ? 1 : 0 }} />
      {children}
    </Fab>
  )
}

export default BackFab