import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import styled from 'styled-components'

interface Props {
  children: ReactNode
}

const FabControlBox = styled(Box)`
  position: fixed;
  bottom: 0px;
  display: inline-flex;
  align-items: center;

  .MuiFab-default {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
`

export function FabControls({ children }: Props) {
  return (
    <FabControlBox
      sx={{
        left: { xs: 0, md: '240px' }, 
        justifyContent: 'center', 
        paddingBottom: 3,
        width: { xs: '100vw', md: 'inherit' },
      }}>
      {children}
    </FabControlBox>
  )
}

export default FabControls
