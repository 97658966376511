import { useContext, createContext } from 'react'

interface TaggableItem {
  tags?: string[]
}

interface DrawerContextProps {
  toggleDrawer: () => void
}

export const DrawerContext = createContext<DrawerContextProps>(null!)

export function itemByTags(item: TaggableItem, tags: string[]) {
  if(!item.tags) return false

  return tags.some(tag => item.tags?.includes(tag))
}

export function isValidExternalURL(x: unknown): x is string {
  return typeof x === 'string' && /^https?:\/\//.test(x)
}

export function isValidAppPath(x: unknown): x is string {
  return typeof x === 'string' && /^\//.test(x)
}

export function useDrawer() {
  return useContext(DrawerContext)
}
