import { useState, useCallback, useEffect } from 'react'
import { Joke } from '../types'
import useAPI from './useAPI'

export function useJokes() {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [jokes, setJokes] = useState<Joke[]>([])

  const { API } = useAPI()

  const fetchJokes = useCallback(async () => {
    setIsLoading(true)
    setHasError(false)
    const { data: { data }, status } = await API.get('/dadJokes')
    setIsLoading(false)
    if(status === 200) {
      setJokes(data)
    } else {
      setHasError(true)
    }
  }, [])

  useEffect(() => {
    fetchJokes()
  }, [])

  return { jokes, isLoading, hasError }
}

export default useJokes
