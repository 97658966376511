/**
 * TODO: Should come from @uchilaka/jokes-shared instead. Copied 
 *   here for now to implement quickly
 * @param val 
 * @returns 
 */
export function isNumeric(val: unknown): val is number {
  if(isNaN(parseInt(val as string))) return false
  return true
}
