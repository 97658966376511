import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import FabControls from './FabControls'
import BackFab from './BackFab'

export function ShowPrep() {
  return (
    <>
      <Typography variant="h4">Instructions for Teams</Typography>

      <Divider>Preparing for the Show</Divider>

      <Typography variant="h6">Time box: 3 minutes</Typography>
      <Typography variant="body2" component="div">
        <p>Each team is tasked with helping their presenter complete these 3 tasks</p>
        <ul>
          <li>Decide between a stand up set and a TED talk</li>
          <li>Pick out a stage name and intro</li>
          <li>Write down your stage name and intro on 2 post-it notes, and turn one in to the show host</li>
        </ul>
      </Typography>

      <Divider>During the Show</Divider>

      <Typography variant="h5">Be Loud and Proud!</Typography>
      <Typography variant="body2">Support your presenter in any way possible - especially by cheering them on during their set</Typography>      
      <FabControls>
        <BackFab href="/setup" />
      </FabControls>
    </>
  )  
}

export default ShowPrep
