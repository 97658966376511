import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Layout from './Layout'

export function Setup() {
  return (
    <Layout>
      {/* TODO: Build dynamic support for club vs. cafe */}
      <Typography variant="h3">Welcome to "The Melt" Café</Typography>
      <Typography variant="h4">This is a judgement free, psychologically safe café.</Typography>
      <Grid container spacing={2} rowSpacing={4}>
        <Grid item>
          <Typography variant="body2">I'm your [terrified / excited / nervous / anxious] host, [MC name]</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button size="large" href="/jokes">Jokes</Button>
        </Grid>
        <Grid item>
          <Typography variant="h4">Let's all get out of our seats!</Typography>
          <Typography variant="body2">Instruct your audience to form a straight line in random order e.g. by order of birth month and day</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">Break up into groups with at least 4 people in each group</Typography>
          <Typography variant="body2">Build your cohort of "possies" / "hype teams"</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">Stand in a circle and point a finger in the middle of that circle</Typography>
          <Typography variant="body2">Setup your audience to make an organic selection of their leader</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">Now... choose a leader by pointing at someone in your group (60 seconds)</Typography>
          <Typography variant="body2">You'll know your leader when you're all pointing at the same person</Typography>
        </Grid>
        <Grid item>
          {/* TODO: Include instructions for hosts when moderating the show */}
          <Button href="/instructions/team">Instructions for Teams</Button>
          <Button href="/instructions/talent">Instructions for Presenters (FKA Leaders)</Button>
        </Grid>
        <Grid item>        
          <Typography variant="h4">In the spirit of "breaking the ice" - I'll got first with my passion subject and 1 joke</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">Everyone in this cafè is invested in your success! You've got this</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">Now! Give it up, for [next speaker intro], [next spreaker]</Typography>
        </Grid>
        <Grid item>
          <Button size="large" href="/jokes">Jokes</Button>
        </Grid>
        <Grid item>
          <Typography variant="h4" fontWeight={400}>
            To our guests and the rest of our audience - you've been so gracious! Thank you!!
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Setup
