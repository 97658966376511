import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import BackFab from './BackFab'
import FabControls from './FabControls'

export function InstructionsForPresenters() {
  return (
    <>
      <Typography variant="h4">Instructions for Presenters</Typography>

      <Typography variant="body2" component="div">
        <p>Congrats! In the next several minutes, you will represent your team in an open mic set with their help and support.</p>
        <p>You will have a maximum of 120 seconds for your set</p>
        <p>Jeff Soriano had a really beautiful description for the spirit of what we're going for - "a trust fall, but with microphones". As the presenter for your group, you have a choice between two prompts</p>
        <p>For your set, you will have a choice between stand-up comedy and "your shortest TED talk ever". <strong>Your team will help you decide on and prepare for your set</strong></p>
      </Typography>

      <Divider>During the Show</Divider>

      <Typography variant="h6">Time box: 2 minutes</Typography>

      <Typography variant="h5" fontWeight={400}>Option A: Stand-up comedy set (2 minutes max.)</Typography>
      <Typography variant="body2" component="div">
        You will be presenting a stand-up (comedy) set on behalf of your team
        <ul>
          <li>It's your big break as a comedian! You will come up to the stage and present a minimum of 2 jokes for your team</li>
          <li>You can present any jokes you like - our Dad Jokes Curator In-Chief (thank you <a href="mailto:emily.chilaka@gmail.com?subject=Thank%20you%20for%20curating%20our%20dad%20jokes!" target="_blank">Em</a>!) has provided a set of dad jokes on this tablet as material should you need it</li>
        </ul>
      </Typography>

      <Typography variant="h5" fontWeight={400}>Option B: Your TED talk (2 minutes max.)</Typography>
      <Typography variant="body2" component="div">
        You will be presenting your shortest TED talk ever on a subject you're passionate about.
        <ul>
          <li>You've been selected as a TED speaker since the world took notice of how enthusiastic you are about [subject name].</li>
          <li>So... give us the elevator pitch - Tell us about [subject name] in 120 seconds</li>
          <li>As a guide, you can follow the format:
            <ol>
              <li>Define your subject</li>
              <li>Tell a personal story</li>
              <li>Wrap up with your takeaway</li>
            </ol>
          </li>
        </ul>
      </Typography>

      <FabControls>
        <BackFab href="/setup" />
      </FabControls>
    </>
  )  
}

export default InstructionsForPresenters

